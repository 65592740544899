<!-- 头部组件-2023/10/18 -->
<template>
  <div id="HeaderView">
    <div class="left">
      <span v-if="tokenid" class="left-content" @click="home">
        <img class="logo" src="../assets/images/logo.png" alt="">
        <span>TDC Node Management</span>
      </span>
    </div>
    <div class="i18">
      <div class="android" @click="tutorial">{{ $t('home.home69') }}</div>
      <div class="android" @click="android">Android</div>
      <div v-if="tokenid" class="setup">
        <i class="el-icon-setting icons" @click="open">
          <div v-if="isShow" class="setupframe">
            <div class="first">
              <i class="el-icon-user-solid">
                <span>{{ userName }}</span>
              </i>
            </div>
            <div class="second" @click="changePassword">
              <div>
                <img class="logo" height="16.8" src="../assets/images/password.png" alt=""
                  style="vertical-align: middle;">
                <span style="vertical-align: middle;">{{ $t('header.header1') }}</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="third" @click="exit">
              <div>
                <img class="logo" height="16.8" src="../assets/images/exit.png" alt="" style="vertical-align: middle;">
                <span style="vertical-align: middle;">{{ $t('header.header2') }}</span>
              </div>
              <div>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </i>

      </div>
      <el-select v-model="value"  size="mini"
        @change="changeLanguage(value)">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { mapGetters, mapActions } from 'vuex';
import { setStorage, getStorage } from '@/utils/localStorage'
export default {
  name: 'HeaderView',
  data() {
    return {
      options: [{
        value: '0',
        label: 'EN'
      }, {
        value: '1',
        label: 'CN'
      }],
      value: '1',
      isShow: false,
    }
  },

  mounted() {
    this.language()
  },
  methods: {
    ...mapActions('user', [
      'setTokenId',
      'setUserName',
      'setChangePassword'
    ]),
    // 判断中英文
    language() {
      if (getStorage('lang') === 'undefined' || !getStorage('lang')) {
        this.$i18n.locale = 'zh'
        this.value = '1'
      } else if (getStorage('lang') === 'en-US') {
        this.$i18n.locale = 'en'
        this.value = '0'
      } else if (getStorage('lang') === 'zh-CN') {
        this.$i18n.locale = 'zh'
        this.value = '1'
      }
    },
    // 国际化语言切换
    changeLanguage(i) {
      if (i == '0') {
        this.$i18n.locale = 'en'
        this.value = '0'
        setStorage('lang', 'en-US')

      } else {
        this.$i18n.locale = 'zh'
        this.value = '1'
        setStorage('lang', 'zh-CN')
      }
    },
    // 点击设置
    open() {
      this.isShow = !this.isShow
    },
    // 修改密码
    changePassword() {
      this.setChangePassword(1)
      this.$router.push('/password/')
    },
    // 退出
    exit() {
      this.$confirm(this.$t('header.header3'), this.$t('home.home39'), {
        confirmButtonText: this.$t('password.password2'),
        cancelButtonText: this.$t('home.home59'),
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('header.header4')
        });
        this.isShow = false
        // 清空缓存
        localStorage.token = '';
        this.setTokenId()
        this.setUserName()
        this.$router.replace('/login/')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('header.header5')
        });
      });
    },
    // 跳转首页
    home() {
      this.$router.push('/home/')
    },
    // 使用教程
    tutorial() {
      if (this.value === '0') {
        window.open("/Node Management User Manual_V1.0.3.pdf")
      } else {
        window.open("/节点管理使用手册-V1.0.3.pdf")
      }
    },
    // 安卓下载
    android() {
      window.open('https://xinjipooss.oss-ap-southeast-1.aliyuncs.com/web/NodeManager.apk')
    }
  },
  computed: {
    ...mapGetters('user', [
      'tokenid',
      'userName',
    ]),
  }
}
</script>

<style lang="less" scoped>
#HeaderView {
  position: relative;
  line-height: 48px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  .left {

    .left-content {
      // margin-left: 20px;
      cursor: pointer;

      .logo {
        height: 26px;
        vertical-align: middle;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        font-size: 16px;
      }
    }

  }

  .i18 {
    display: flex;
    justify-content: end;
    // padding-right: 50px;

    .android {
      margin-right: 20px;
      color: #246EFF;
      cursor: pointer;
    }

    .icons {
      font-size: 26px;
      margin-right: 20px;
      vertical-align: middle;
      cursor: pointer;
      position: relative;

      .setupframe {
        position: absolute;
        top: 40px;
        right: -90px;
        font-size: 14px;
        // background: red;
        width: 200px;
        padding: 20px;
        border-radius: 8%;
        z-index: 9999;
        background: #FFFFFF;
        box-shadow: 0px 10px 14px #C3C5C9;

        i {
          color: #282828;
        }

        span {
          color: #000000;
          margin-left: 10px;
        }

        .first {
          padding-bottom: 10px;
          border-bottom: 1px solid #F4F4F4;
        }

        .second {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px 0px;
        }

        .third {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    /deep/ .el-input__inner {
      border: 2px solid #282828;
      border-radius: 8px;
      background-color: transparent;
      width: 65px;
    }

    /deep/.el-select .el-input .el-select__caret {
      color: #000000;
    }
  }

}
</style>
<style lang="less" scoped>
@media only screen and (max-width: 990px) {
  #HeaderView {
    .left {
      .left-content {
        span {
          display: none;
        }
      }
    }

    .i18 {
      .android {
        margin-right: 10px;
      }
    }
  }
}
</style>
