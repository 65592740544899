// 获取浏览器传参
export function getUrlParam (language) {
  const url = window.location.href
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + language + '=' + '([^&;]+?)(&|#|;|$)').exec(
        url// eslint-disable-next-line
            ) || [, ''])[1].replace(/\+/g, '%20')
    ) || null
  )
}
