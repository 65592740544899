import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    alias: '/',
    name: 'login',
    component: () => import( /* webpackChunkName: "login" */ '@/views/admin/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import( /* webpackChunkName: "register" */ '@/views/admin/Register.vue')
  },
  {
    path: '/password',
    name: 'password',
    component: () => import( /* webpackChunkName: "password" */ '@/views/admin/Password.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
