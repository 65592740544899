<template>
  <div id="app">
      <header-view></header-view>
      <router-view />
  </div>
</template>

<script>
import HeaderView from './components/HeaderView.vue'
export default {
  components: {
    HeaderView
  },
  methods: {
    handleResize() {
      if (document.documentElement.clientWidth > 1024) {
        this.$nextTick(() => {
          const baseConstn = 40 // 1920的设计稿 / 基数37
          const nowWidth = document.documentElement.clientWidth
          const nowCount = nowWidth / baseConstn
          document.querySelector('html').style.fontSize = nowCount + 'px'
        })
      }
    }
  },
  mounted() {
    // 首次计算和设置根元素的大小
    if (document.documentElement.clientWidth > 1024) {
      const baseConstn = 40 // 1920的设计稿 / 基数37
      const nowWidth = document.documentElement.clientWidth
      const nowCount = nowWidth / baseConstn
      document.querySelector('html').style.fontSize = nowCount + 'px'
    }

    // 添加 resize 事件的监听函数
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    // 移除 resize 事件的监听函数
    window.removeEventListener('resize', this.handleResize)
  }

}

</script>

<style lang="less">
// .el-header{
//   background-color: #FFFFFF;
// }

// // .el-footer{
// //   background-color: #1c1c1f;
// // }

// html,
// body {
//   background-color: #FFFBEC;
// }
</style>
