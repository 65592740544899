const user = {
    namespaced: true, // 开启命名空间
    state: {
        tokenId: '',//携带token
        userName: '',//用户名
        changePassword:'',//忘记密码，修改密码切换
    },
    mutations: {
        ['SAVE_TOKEN_Id'](state, token) {
            state.tokenId = token
        },
        ['SAVE_USER_NAME'](state, userName) {
            state.userName = userName
        },
        ['SAVE_CHANGE_PASSWORD'](state, changePassword) {
            state.changePassword = changePassword
        },
        
    },
    actions: {
        setTokenId({ commit }, tokenId) { commit('SAVE_TOKEN_Id', tokenId) },
        setUserName({ commit }, userName) { commit('SAVE_USER_NAME', userName) },
        setChangePassword({ commit }, changePassword) { commit('SAVE_CHANGE_PASSWORD', changePassword) }
    },
    getters: {
        tokenid(state) {
            return state.tokenId
        },
        userName(state) {
            return state.userName
        },
        changePassword(state) {
            return state.changePassword
        },
    }
}
export default user 