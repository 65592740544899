export function setStorage (key, value) {
  localStorage.setItem(key, value)
}

export function getStorage (key) {
  return localStorage.getItem(key)
}

export function removeStorage (key) {
  return localStorage.removeItem(key)
}

// 查看用户当前登录账号的链地址
export function getLoginUrl () {
  if (getStorage('trc')) {
    return 'trc'
  } else if (getStorage('irc')) {
    return 'irc'
  } else {
    return undefined
  }
}
