export default {
  login: {
    login1: 'Sign in',
    login2: 'Enter username',
    login3: 'Enter password',
    login4: 'Forget password?',
    login5: 'New user/',
    login6: 'Sign up',
    login7: 'User name is email address. Please enter the correct address',
    login8: 'The password contains a combination of 8 to 12 digits and letters',
    login9: 'Start here',
  },
  register:{
    register1: 'Enter email',
    register2: 'Enter the verification code',
    register3: 'Retrieve it in X seconds',
    register4: 'Set Password',
    register5: 'Confirm password',
    register6: 'Read and agree',
    register7: 'User Agreement',
    register8: 'Sign in directly',
    register9: 'Get verification code',
    register10: 'Enter email address',
    register11: 'Enter correct email address',
    register12: 'The verification code contains 6 characters',
    register13: 'Enter password again',
    register14: 'Passwords do not match!',
    register15: 'Email cannot be empty',
    register16: 'Invalid email format',
    register17: 'Sent',
    register18: 'Account creat successfully',
  },
  password:{
    password1: 'Reset Password',
    password2: 'Confirm',
    password3: 'Set new password',
    password4: 'Enter original password',
    password5: 'Confirm new password',
    password6: 'Enter new password',
    password7: 'Password changed successfully',
    password8: 'Password reset successful',
    password9: 'Forget password',
  },
  home:{
    home1: 'Node Management',
    home2: 'Add node',
    home3: 'Node height',
    home4: 'Network height',
    home5: 'Node version',
    home6: 'Latest version',
    home7: '(Stopped)',
    home8: '(Running)',
    home9: 'Start',
    home10: 'Stop',
    home11: 'Restart',
    home12: 'Update',
    home13: 'Node state',
    home14: 'Add node information',
    home15: 'Node name',
    home16: 'Node IP',
    home17: 'Enter node IP',
    home18: 'Node port',
    home19: 'Enter node port',
    home20: 'Client username',
    home21: 'Enter client username',
    home22: 'Client password',
    home23: 'Enter client password',
    home24: 'Save',
    home25: 'Delete node',
    home26: 'Enter the IP address',
    home27: 'Enter valid IP address',
    home28: 'Enter the port',
    home29: 'Enter valid port',
    home30: 'Node name cannot be empty',
    home31: '3 to 15 characters',
    home32: 'Client username cannot be empty',
    home33: 'At least 4 characters',
    home34: 'Client password cannot be empty',
    home35: 'At least 6 characters',
    home36: 'Fetching node information, please wait',
    home37: 'Adding node information, please wait',
    home38: 'This operation will permanently delete the node. Do you want to continue?',
    home39: 'Prompt',
    home40: 'Successfully deleted',
    home41: 'Cancel deletion',
    home42: 'This operation will start the node. Do you want to continue?',
    home43: 'Starting, please wait',
    home44: 'Successfully started',
    home45: 'Cancel start',
    home46: 'This operrarion will stop the node. Do you want to continue?',
    home47: 'Stopping, please wait',
    home48: 'Successfully stopped',
    home49: 'Cancel stop',
    home50: 'This operrarion will restart the node. Do you want to continue?',
    home51: 'Restarting, please wait',
    home52: 'Successfully restarted',
    home53: 'Cancel restart',
    home54: 'This operrarion will update the node. Do you want to continue?',
    home55: 'Updating, please wait',
    home56: 'Successfully updated',
    home57: 'Cancel update',
    home58: 'No nodes available',
    home59: 'Cancel',
    home60: '(Update)',
    home61: 'Starting',
    home62: 'Stopping',
    home63: 'Restarting',
    home64: 'Updating',
    home65: 'view log file',
    home66: 'Operation successfully completed',
    home67: 'Updateable',
    home68: 'Enter the node name',
    home69: 'Instructional',
  },
  header:{
    header1:'Change password',
    header2:'Exit',
    header3:'Exit, do you want to continue?',
    header4:'Exit successful',
    header5:'Cancel exit',
  }
}
