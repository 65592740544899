import Vue from 'vue'

import {
  Col,
  Row,
  Button,
  Icon,
  NavBar,
  DropdownMenu,
  DropdownItem,
  Toast,
  Swipe,
  SwipeItem,
  Tab,
  Tabs,
  Grid,
  GridItem,
  Image as VanImage,
  Popup,
  Dialog,
  Loading,
  Field,
  Picker,
  Popover,
  List,
  PullRefresh,
  radioGroup,
  radio
} from 'vant'

Vue.prototype.$toast = Toast
Vue.prototype.$dialog = Dialog

Vue.use(Col)
Vue.use(Row)
Vue.use(Button)
Vue.use(Icon)
Vue.use(NavBar)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(VanImage)
Vue.use(Popup)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Popover)
Vue.use(List)
Vue.use(PullRefresh)
Vue.use(radioGroup)
Vue.use(radio)
