/* eslint-disable */
import QRCode from 'qrcode'

// PC / 手机判断
export function platFormCheck () {
	const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
	return flag
}

// 安卓 / iOS判断
export function mobileTypeCheck () {
	const ua = navigator.userAgent.toLowerCase()
	//android终端
	const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1
	//ios终端
	const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

	if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
		return 'iOS'
	} else if(/(Android)/i.test(navigator.userAgent)) {
		return 'android'
	}
}

// 保存图片
export function saveImg (src, fileName) {
	return new Promise((resolve, reject) => {
    if (src && fileName) {
      const img = new Image()
      let newurl
      //设置图片跨域
      img.setAttribute('crossOrigin', 'anonymous')
      img.src = src
      img.onload = function() {
        let can =  document.createElement('canvas')
        can.width = 150
        can.height = 150
        let ctx = can.getContext('2d')
        ctx.drawImage(img, 0, 0, 150, 150)
        newurl = can.toDataURL('image/png')
        let a = document.createElement('a')
        let event = new MouseEvent('click')
        a.download = fileName
        a.href = newurl
        a.dispatchEvent(event)
      }
      resolve(true)
    } else {
      reject(false)
    }
	})
}

// 二维码生成
export function createQRCode (urls) {
  return new Promise((resolve, reject) => {
    if (urls) {
      QRCode.toDataURL(urls).then(url => {
        resolve(url)
      }).catch(err => {
        reject(false)
      })
    } else {
      reject(false)
    }
  })
}

// 获取币种
export function getCoinType () {
  if (localStorage.getItem('currentAddress')) {
    const address = localStorage.getItem('currentAddress').replace(/\s*/g, '') // 去掉路径的空格
    const newLength = address.length

    if (newLength === 34 && address.substring(0, 1) === 'T') {
      return 'TRX'
    } else if (newLength === 36 && address.substring(0, 3) === 'ICW') {
      return 'ICW'
    }
  } else {
    return null
  }
}

/**
 * 对源数据截取decimals位小数，不进行四舍五入
 * @param {*} num 源数据
 * @param {*} decimals 保留的小数位数
 */
export const cutOutNum = (num, decimals = 4) => {
  if (isNaN(num) || (!num && num !== 0)) {
    return '-'
  }
 
  function toNonExponential (_num) {
    var m = Number(_num).toExponential().match(/\d(?:\.(\d*))?e([+-]\d+)/)
    return Number(_num).toFixed(Math.max(0, (m[1] || '').length - m[2]))
  }
  
  num = toNonExponential(num)
  
  const pointIndex = String(num).indexOf('.') + 1
  
  const pointCount = pointIndex ? String(num).length - pointIndex : 0
 
  function zeroFill (zeroNum, num) {
    for (let index = 0; index < zeroNum; index++) {
      num = `${num}0`
    }
    return num
  }
 
  if (pointIndex === 0 || pointCount <= decimals) {
    let tempNumA = num

    if (pointIndex === 0) {
      tempNumA = `${tempNumA}.`
      tempNumA = zeroFill(decimals - pointCount, tempNumA)
    } else {
      tempNumA = zeroFill(decimals - pointCount, tempNumA)
    }
    return String(tempNumA)
  }
 
  const Int = String(num).split('.')[0]
  const Decimal = String(num).split('.')[1].substring(0, decimals)
  const tempNumB = `${Int}.${Decimal}`

  return Number(tempNumB) === 0 ? 0 : tempNumB
}
