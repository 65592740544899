import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state:()=>({
  }),

  mutations: {
  },

  actions: {
  },
  
  getters: {
  },




  modules: {
    // 模块化数据
    user
  },
  plugins: [
    // veux持久化配置
    createPersistedState({
      storage: window.localStorage,
      key: 'my-data', // 设置存储的键名
      paths: ['user'],//持久化模块
    })
  ]
})
