import Vue from 'vue'
import ElementUI from 'element-ui'
import VCharts from 'v-charts'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './core/lazy_use' // use lazy load components
import { i18n, vantLocales } from './lang'
import { platFormCheck, mobileTypeCheck, getCoinType } from '@/utils/util'
import 'amfe-flexible' // 移动端适配
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'
import './assets/icon/iconfont.css'
import axios from 'axios'
import { post } from './api/https'
import md5 from 'js-md5'

// axios.defaults.baseURL = '/jsonrpc'  //关键代码 正式
axios.defaults.baseURL = '/'  //关键代码 测试
Vue.config.productionTip = false


Vue.prototype.$EventBus = new Vue()
Vue.prototype.$platForm = platFormCheck()
Vue.prototype.$mobileType = mobileTypeCheck()
Vue.prototype.$getCoinType = getCoinType()
Vue.prototype.$md5 = md5
//定义全局变量
Vue.prototype.$post = post

vantLocales(i18n.locale)

// if (document.documentElement.clientWidth > 1024) {
//   // 适配不同尺寸屏幕（动态计算根元素的大小fonSize）
//   function resizeCount () {
//     const baseConstn = 38 // 1920的设计稿 / 基数37.5
//     const nowWidth = document.documentElement.clientWidth
//     const nowCount = nowWidth / baseConstn
//     document.querySelector('html').style.fontSize = nowCount + 'px'
//   }
//   // 初始化
//   resizeCount()

//   window.addEventListener('resize', () => {
//     resizeCount()
//   })
// }
Vue.use(ElementUI)
Vue.use(VCharts)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
