export default {
  login: {
    login1: '登录',
    login2: '请输入用户名',
    login3: '请输入密码',
    login4: '忘记密码?',
    login5: '新用户/',
    login6: '注册',
    login7: '用户名为邮箱地址，请输入正确的地址',
    login8: '密码必须是8到12位的数字和字母的组合',
    login9: '登陆成功',  
  },
  register:{
    register1: '请输入邮箱',
    register2: '请输入验证码',
    register3: '秒后重新获取',
    register4: '请设置密码',
    register5: '请确认密码',
    register6: '已阅读并同意',
    register7: '用户协议',
    register8: '直接登录',
    register9: '获取验证码',
    register10: '请输入邮箱地址',
    register11: '请输入正确的邮箱地址',
    register12: '验证码长度为6个字符',
    register13: '请再次输入密码',
    register14: '两次输入密码不一致!',
    register15: '邮箱不能为空',
    register16: '邮箱格式不正确',
    register17: '已发送',
    register18: '账号创建成功',
  },
  password:{
    password1: '重置密码',
    password2: '确认',
    password3: '请设置新密码',
    password4: '请输入原始密码',
    password5: '请确认新密码',
    password6: '请输入新密码',
    password7: '密码修改成功',
    password8: '密码重置成功',
    password9: '忘记密码',
  },
  home:{
    home1: '节点管理',
    home2: '添加节点',
    home3: '节点高度',
    home4: '全网高度',
    home5: '节点版本',
    home6: '最新版本',
    home7: '(已停止)',
    home8: '(运行中)',
    home9: '启动',
    home10: '停止',
    home11: '重启',
    home12: '更新',
    home13: '节点状态',
    home14: '添加节点信息',
    home15: '节点名称',
    home16: '节点IP',
    home17: '请输入节点IP',
    home18: '节点端口',
    home19: '请输入节点端口',
    home20: '客户端用户名',
    home21: '请输入客户端用户名',
    home22: '客户端密码',
    home23: '请输入客户端密码',
    home24: '保存',
    home25: '删除节点',
    home26: '请输入IP地址',
    home27: '请输入正确格式IP',
    home28: '请输入端口',
    home29: '请输入正确格式的端口',
    home30: '节点名称不能为空',
    home31: '长度在 3 到 15 个字符',
    home32: '客户端用户名不能为空',
    home33: '长度最少4个字符',
    home34: '客户端密码不能为空',
    home35: '长度最少6个字符',
    home36: '正在获取节点信息，请稍后',
    home37: '正在添加节点信息，请稍后',
    home38: '此操作会永久删除该节点,是否继续?',
    home39: '提示',
    home40: '删除成功',
    home41: '取消删除',
    home42: '此操作会启动该节点,是否继续?',
    home43: '正在启动中，请稍后',
    home44: '启动成功',
    home45: '取消启动',
    home46: '此操作会停止该节点,是否继续?',
    home47: '正在停止中，请稍后',
    home48: '停止成功',
    home49: '取消停止',
    home50: '此操作会重启该节点,是否继续?',
    home51: '正在重启中，请稍后',
    home52: '重启成功',
    home53: '取消重启',
    home54: '此操作会更新该节点,是否继续?',
    home55: '正在更新中，请稍后',
    home56: '更新成功',
    home57: '取消更新',
    home58: '暂无节点',
    home59: '取消',
    home60: '(更新)',
    home61: '启动中',
    home62: '停止中',
    home63: '重启中',
    home64: '更新中',
    home65: '查看日志',
    home66: '操作成功',
    home67: '可更新',
    home68: '请输入节点名称',
    home69: '使用教程',
  },
  header:{
    header1:'修改密码',
    header2:'退出',
    header3:'退出, 是否继续?',
    header4:'退出成功',
    header5:'取消退出',
  }
  
}
